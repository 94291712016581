<template>
  <div id="waitingBlock">
    <h2>Ваша заявка принята!</h2>

    <div style="background: none" id="loadingBox">
      <h3 id="timer">{{ time }}</h3>
      <div id="loading">
        <div id="whiteCircle"></div>
      </div>
    </div>
    <!--    <h3>{{loaderDescription[loaderCount]}}</h3>-->
    <h3>Решение принимается</h3>

    <p class="misc">
      Уважаемый клиент, мы приняли вашу заявку и обрабатываем ее
    </p>
    <p>
      <span v-if="valueVuex.CALC_WEEK !== 52">В случае одобрения, деньги поступят на вашу карту<br /></span>
      <span>Чтобы иметь всю необходимую информацию по займу.<br />
        Установите наше<a target="_blank" href="http://bit.ly/3Mkk8f7 ">приложение</a>
      </span>
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'formScreenWaiting',
  data() {
    return {
      time: 100,
      loaderDescription: [
        'Делаем запрос в Бюро Кредитных Историй',
        'Проверяем кредитную историю',
        'Осуществляем скоринговую проверку',
        'Определяем доступный лимит',
        'Подбираем персонального менеджера',
        'Подготавливаем документы',
        'Создаем личный кабинет'
      ],
      loaderCount: 0
    };
  },
  methods: {
    timer() {
      setTimeout(() => {
        this.time -= 1;
        if (this.time <= 0) {
          this.time = 680;
        }
        this.timer();
      }, 1000);
    },
    startLoaderDesc() {
      let self = this;
      if (this.loaderCount < this.loaderDescription.length - 1) {
        setTimeout(() => {
          self.loaderCount += 1;
          self.startLoaderDesc();
        }, 25000);
      }
    },
    baseLoanGetDesign(longId, iteration = 0) {
      return new Promise((resolve, reject) => {
        this.$external.consoleDebug('baseLoanGetDesign', 'open');
        let timeOut;
        switch (iteration) {
          case 0:
            timeOut = 5000;
            break;
          case 1:
          case 2:
            timeOut = 20000;
            break;
          case 3:
          case 4:
            timeOut = 10000;
            break;
          default:
            timeOut = 5000;
            break;
        }
        iteration += 1;
        setTimeout(() => {
          this.$external.consoleDebug({ iteration, timeOut, longId });
          this.$external.universalRequest({
              externalType: 'zita',
              action: 'CheckDecisionByIDBaseLoan',
              id: this.longId
            })
            .then((res) => {
              this.$external.consoleDebug('timeout then');
              this.$external.consoleDebug(res);
              if (res.onItsWay === 'true') {
                resolve({
                  DealProductType: res.DealProductType,
                  isPDL: res.DealProductType
                });
              } else {
                this.baseLoanGetDesign(longId, iteration)
                  .then((res) => resolve(res))
                  .catch((res) => reject(res));
              }
            })
            .catch((res) => {
              this.$external.consoleDebug('timeout catch');
              this.$external.consoleDebug(res);
            })
            .finally(() => {
              this.$external.consoleDebug('baseLoanGetDesign', 'close');
            });
        }, timeOut);
      });
    },

    whatHappenedWithPdl(iteration = 0) {
      //localStorage.setItem('DemandId'
      let timeOut;
      switch (iteration) {
        case 0:
          timeOut = 5000;
          break;
        case 1:
        case 2:
          timeOut = 20000;
          break;
        case 3:
        case 4:
          timeOut = 10000;
          break;
        default:
          timeOut = 5000;
          break;
      }
      iteration += 1;
      setTimeout(() => {
        this.$external.universalRequest({
            externalType: 'zita',
            action: 'CheckDecisionByDemand',
            demand: this.$store.state.repeatLoan.system.loanDemandId,
          })
          .then((res) => {
            if (res.inWork === 'false') {
              const newScreen = res.decision === 'true' ? 'thankYouPage' : 'fullFail';
              this.$store.dispatch('repeatLoan/changeCurrentScreen', newScreen);
            } else {
              this.whatHappenedWithPdl(iteration);
            }
          })
          .catch((res) => {
            this.$external.consoleDebug(res);
              this.$store.dispatch('repeatLoan/changeCurrentScreen', 'fullFail');
          });
      }, timeOut);
    }
  },
  computed: {
    ...mapState({
      valueVuex: (state) => state.repeatLoan.sendData,
      longId: (state) => state.repeatLoan.system.longId
    })
  },
  mounted() {
    this.timer();
    if (this.valueVuex.CALC_WEEK === 52) {
      this.baseLoanGetDesign(this.longId)
        .then((res) => {
          if (res.DealProductType === 'true') {
            this.$store.dispatch('repeatLoan/changeCurrentScreen', 'thankYouPage');
          } else {
            this.$store.dispatch('repeatLoan/changeCurrentScreen', 'rejection');
          }
        })
        .catch((res) => {
          this.$store.dispatch('repeatLoan/changeCurrentScreen', 'rejection');
        });
    } else {
      this.whatHappenedWithPdl();
    }
  }
};
</script>

<style scoped>
h2 { text-align: center }
p { color: #959595; margin-bottom: 19.41vw  }
#waitingBlock { display: flex; flex-direction: column; align-items: center; text-align: center}
#waitingBlock > div { width: 69.71vw; display: flex; align-items: center; justify-content: center; margin-bottom: 5.29vw; padding: 12.06vw 0 }
.misc { text-align: center; margin-bottom: 8.24vw}
#loadingBox { position: relative }
#timer { position: absolute; top: 20.6vw; margin: 0; z-index: 10}
#loadingBox > #loading { content: ' '; width: 22.94vw; height: 22.94vw; background: conic-gradient(rgba(0, 86, 63, 1), white);  -moz-border-radius: 100px; -webkit-border-radius: 100px; border-radius: 100px; display: flex; align-items: center; justify-content: center;  -webkit-animation: spin 2s linear infinite; animation: spin 2s linear infinite}
#loadingBox > #loading > #whiteCircle { content: ' '; width: 15vw; height: 15vw; background: white;  -moz-border-radius: 100px; -webkit-border-radius: 100px; border-radius: 100px; }
@-webkit-keyframes spin {
  from { transform: rotateZ(0deg) scale(1);}
  50% { transform: rotateZ(540deg) scale(0.9) }
  to { transform: rotateZ(1080deg) scale(1);}
}

@keyframes spin {
  from { transform: rotateZ(0deg) scale(1);}
  50% { transform: rotateZ(540deg) scale(0.9) }
  to { transform: rotateZ(1080deg) scale(1); }
}
@media (min-width: 760px) {
  h2 {margin-bottom: 2.98vw}
  p { margin-bottom: 20.40vw; width: 100% }
  #waitingBlock { padding: 0 7.10vw }
  #waitingBlock > div { width: 28.31vw; margin-bottom: 2.82vw; padding: 4.92vw 0}
  #loadingBox > #loading { width: 8.63vw; height: 8.63vw; -moz-border-radius: 100px; -webkit-border-radius: 100px; border-radius: 100px; }
  #loadingBox > #loading > #whiteCircle { content: ' '; width: 6.5vw; height: 6.5vw; }
  .misc { margin-bottom: 4.24vw}
  #timer { top: 7.9vw }
}
@media (min-width: 1240px) {
  #anketaSteps > div > h2 { margin-bottom: 37px }
  p { margin-bottom: 248px }
  a { font-size: 14px }
  h3 { margin-bottom: 20px }
  #waitingBlock { padding: 0 88px }
  #waitingBlock > div { width: 351px; margin-bottom: 35px; padding: 61px 0}
  .misc { margin-bottom: 20px}
  #loadingBox > #loading { width: 107px; height: 107px; -moz-border-radius: 100px; -webkit-border-radius: 100px; border-radius: 100px; }
  #loadingBox > #loading > #whiteCircle { content: ' '; width: 80px; height: 80px; -moz-border-radius: 100px; -webkit-border-radius: 100px; border-radius: 100px;}
  #timer { top: 94px }

}
</style>
